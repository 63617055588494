import { useState, useEffect } from "react";

export default function Maindiv() {
  var startTime = 1705201066350 / 1000;
  var time = Date.now().valueOf() / 1000;
  const [diffTime, setDiffTime] = useState(Math.floor(time - startTime));

  useEffect(() => {
    setTimeout(() => {
      var newTime = Date.now().valueOf() / 1000;
      setDiffTime(Math.floor(newTime - startTime));
    }, 1000);
  }, [diffTime]);

  return (
    <div style={{ minHeight: "85dvh", padding: "20px", textAlign: "center" }}>
      <h4>
        Make{" "}
        <b
          style={{
            fontSize: "30px",
            color: "grey",
          }}
        >
          "Studiorad.in"
        </b>{" "}
        your business place at just
      </h4>
      <div className="pb-3">
        <b style={{ fontSize: "30px" }}>
          &#8377; {Math.floor(1250 + (1250 / (365 * 3600 * 24)) * diffTime, 4)}
        </b>
        &nbsp;
        <b style={{ fontSize: "12px" }}>
          (
          {Math.floor(
            ((1250 + (1250 / (365 * 3600 * 24)) * diffTime) % 1) * 10000
          ) / 100}{" "}
          paise)
        </b>
      </div>
      <div
        style={{ paddingTop: "40px" }}
        className="d-flex justify-content-center"
      >
        <div
          style={{
            transform: "rotate(343deg)",
            minWidth: "150px",
            height: "40px",
            fontSize: "20px",
            marginRight: "-120px",
            marginLeft: "-30px",
            marginTop: "-30px",
            background: "white",
            border: "2px solid black",
          }}
        >
          <b>Hurry up!!</b>
        </div>
        <div
          style={{
            maxWidth: "300px",
            border: "2px solid black",
            padding: "15px",
          }}
        >
          <h5 className="" style={{ fontSize: "14px" }}>
            <p className="">
              &nbsp;&nbsp; Because Prize Will Go Up Every Second
            </p>
          </h5>
        </div>
      </div>
      <div className="pt-5">
        <a target="_blank" href="https://www.instagram.com/ankur_o_0/">

      <button className="btn btn-info">
      Contact
      </button>
        </a>
      </div>
      <br />
    </div>
  );
}
