import logo from './logo.svg';
import './App.css';
import Button from "./Button";
import Footer from "./Footer";
import Maindiv from "./Maindiv";

function App() {
  return (
    <>
    <div className="bg-dark p-1  text-white text-center ">
        <div><b>
        STUDIORAD.IN
        </b>
        </div>
      </div>
      <Maindiv></Maindiv>
      <Footer/>
    </>
  );
}

export default App;
